import { AGENCY_LOGIN, AGENCY_CHECK_TOKEN } from '../App/constants'
import { agencyLoginsuccess, agencyLoginerror, agencyCheckTokensuccess, agencyCheckTokenerror } from '../App/actions'
import { call, put, takeLatest } from 'redux-saga/effects'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

function * remoteLogin(url) {
  try {
    const {login: {token}} = yield call(request, url)
    return token
  } catch (e) {
    console.log('cannot login remotely')
  }
}

export function * agencyLogin ({ credentials }) {
  const loginUrl = `${serverPath}/api/agencies/gettokenfromagencyname?agencyname=${credentials.agencyname}&password=${credentials.agencypassword}`
  const loginTgdApi = `${serverPathTgdApi}/auth/login`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  const body = JSON.stringify({"login": credentials.agencyname, "password": credentials.agencypassword, "type":"agency"})

  const options = {
    method: 'POST',
    headers,
    body
  }

  try {
    if (isB2VM) {
      const responseTgdApi = yield call(request, loginTgdApi, options)
      console.log(responseTgdApi)
      
      const token = yield remoteLogin(loginUrl)
      console.log(token)

      yield put(agencyLoginsuccess(responseTgdApi.accessToken, responseTgdApi.userId, responseTgdApi.isSuperAdmin, responseTgdApi.evalScale, responseTgdApi.agencyName, responseTgdApi.evalScaleDisplay, responseTgdApi.email, responseTgdApi.entity, responseTgdApi.trainings,responseTgdApi.agencyTrainings,responseTgdApi.autorisation, token))
    } else {
      const { login: { token, id, isAdmin, evalScale,trainings, evalScaleDisplay, email } } = yield call(request, loginUrl)
      yield put(agencyLoginsuccess(token, id, isAdmin, evalScale,trainings, credentials.agencyname, evalScaleDisplay, email))
    }

  } catch (err) {
    yield put(agencyLoginerror(err.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * agencyLoginData () {
  yield takeLatest(AGENCY_LOGIN, agencyLogin)
}

export function * agencyCheckToken ({ token }) {
  const loginUrl = `${serverPath}/api/agencies/getagencyfromtoken?token=${token.token}`
  const loginTgdApi = `${serverPathTgdApi}/agencies/getAgencyFromToken`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Authorization', `Bearer ${token.token}`)

  try {
    if (isB2VM) {
      const tokenTgdApi = yield call(request, loginTgdApi, {method: 'GET', headers })
      yield put(agencyCheckTokensuccess(token.token, tokenTgdApi.agencyId, tokenTgdApi.isSuperAdmin, tokenTgdApi.evalScale, tokenTgdApi.agencyName, tokenTgdApi.evalScaleDisplay, tokenTgdApi.email, tokenTgdApi.entity,tokenTgdApi.agencyTrainings, tokenTgdApi.autorisation))
    } else {
        const { login: { token, id, isAdmin, evalScale, agencyname, evalScaleDisplay, email } } = yield call(request, loginUrl)
        yield put(agencyCheckTokensuccess(token, token.tokenTgdApi, id, isAdmin, evalScale, agencyname, evalScaleDisplay, email))
    }
  } catch (err) {
    yield put(agencyCheckTokenerror(err.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * agencyCheckTokenData () {
  yield takeLatest(AGENCY_CHECK_TOKEN, agencyCheckToken)
}
