import { fromJS } from 'immutable'

import {
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_ERROR,
  GET_TOKEN_AGENCY,
  GET_TOKEN_AGENCY_SUCCESS,
  GET_TOKEN_AGENCY_ERROR,
  GET_PLAYERS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_ERROR,
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  GET_AGENCIES_ERROR,
  DELETE_PLAYER,
  DELETE_PLAYER_SUCCESS,
  DELETE_PLAYER_ERROR,
  DELETE_AGENCY,
  DELETE_AGENCY_SUCCESS,
  DELETE_AGENCY_ERROR,
  DELETE_SIMU_PLAYER,
  DELETE_SIMU_PLAYER_SUCCESS,
  DELETE_SIMU_PLAYER_ERROR,
  DELETE_ARCHIVE,
  DELETE_ARCHIVE_SUCCESS,
  DELETE_ARCHIVE_ERROR,
  GET_AGENCYTOKEN,
  GET_AGENCYTOKEN_SUCCESS,
  AGENCY_LOGIN,
  AGENCY_LOGIN_SUCCESS,
  AGENCY_LOGIN_ERROR,
  AGENCY_CHECK_TOKEN,
  AGENCY_CHECK_TOKEN_SUCCESS,
  AGENCY_CHECK_TOKEN_ERROR,
  AGENCY_LOGIN_RESET,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_AGENCY_PASSWORD,
  CHANGE_AGENCY_PASSWORD_SUCCESS,
  CHANGE_AGENCY_PASSWORD_ERROR,
  SIMU_PLAYER_EDIT,
  SIMU_PLAYER_EDIT_SUCCESS,
  SIMU_PLAYER_EDIT_ERROR,
  PLAYER_EDIT,
  PLAYER_EDIT_SUCCESS,
  PLAYER_EDIT_ERROR,
  REFRESH_PLAYERS,
  REFRESH_PLAYERS_SUCCESS,
  REFRESH_PLAYERS_ERROR,
  REFRESH_AGENCIES,
  REFRESH_AGENCIES_SUCCESS,
  REFRESH_AGENCIES_ERROR,
  REFRESH_SIMU_PLAYERS,
  REFRESH_SIMU_PLAYERS_ERROR,
  REFRESH_SIMU_PLAYERS_SUCCESS,
  GET_FILTERED_AGENCIES,
  GET_FILTERED_AGENCIES_SUCCESS,
  GET_FILTERED_AGENCIES_ERROR,
  GET_TOKEN_SIMU,
  GET_TOKEN_SIMU_SUCCESS,
  GET_TOKEN_SIMU_ERROR,
  GET_SIMU_PLAYERS,
  GET_SIMU_PLAYERS_SUCCESS,
  GET_SIMU_PLAYERS_ERROR,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  GET_ARCHIVE_PLAYERS,
  GET_ARCHIVE_PLAYERS_SUCCESS,
  GET_ARCHIVE_PLAYERS_ERROR,
  SET_SCALE_EVAL,
  SET_SCALE_EVAL_SUCCESS,
  SET_SCALE_EVAL_ERROR,
  RESET,
  CREATE_EVAL_FOR_PLAYER,
  CREATE_EVAL_FOR_PLAYER_SUCCESS,
  CREATE_EVAL_FOR_PLAYER_ERROR,
  CREATE_ARCHIVE_PLAYER,
  CREATE_ARCHIVE_PLAYER_SUCCESS,
  CREATE_ARCHIVE_PLAYER_ERROR,
  REVERT_ARCHIVE_PLAYER,
  REVERT_ARCHIVE_PLAYER_SUCCESS,
  REVERT_ARCHIVE_PLAYER_ERROR,
  CREATE_PLAYER,
  CREATE_PLAYER_SUCCESS,
  CREATE_PLAYER_ERROR,
  VERIF_EMAIL,
  VERIF_EMAIL_SUCCESS,
  VERIF_EMAIL_ERROR,
  LOAD_JOURNEYS,
  LOAD_JOURNEYS_SUCCESS,
  LOAD_JOURNEYS_ERROR,
  AGENCY_EDIT,
  AGENCY_EDIT_SUCCESS,
  AGENCY_EDIT_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHECK_TEMPORARY_TOKEN,
  CHECK_TEMPORARY_TOKEN_SUCCESS,
  CHECK_TEMPORARY_TOKEN_ERROR,
  HIDE_MODAL,
} from './constants'

// The initial state of the App
const initialState = fromJS({
})

function appReducer (state = initialState, action) {
  var index
  let outState = state
  switch (action.type) {
    case RESET:
      return initialState
    case CHANGE_AGENCY_PASSWORD:
      return state
        .set('loading', false)
    case CHANGE_AGENCY_PASSWORD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
    case CHANGE_AGENCY_PASSWORD_ERROR:
      return state
        .set('loading', false)
    case CHECK_TEMPORARY_TOKEN:
      return state
        .set('loading', true)
    case CHECK_TEMPORARY_TOKEN_SUCCESS:
      return state
        .set('loading', false)
        .set('validToken', action.token)
        .set('error', false)
    case CHECK_TEMPORARY_TOKEN_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
    case RESET_PASSWORD:
      return state
        .set('loading', true)
    case RESET_PASSWORD_SUCCESS:
      return state
        .set('loading', false)
        .delete('validToken')
        .set('error', false)
    case RESET_PASSWORD_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
    case CHANGE_PASSWORD:
      return state
        .set('loading', false)
    case CHANGE_PASSWORD_SUCCESS:
      return state
        .set('loading', false)
        .set('error', action.error)
    case CHANGE_PASSWORD_ERROR:
      return state
        .set('loading', false)
    case AGENCY_LOGIN:
      return state
        .set('loading', true)
        .set('loginStatus', 'connecting')
        .set('error', false)
    case AGENCY_LOGIN_SUCCESS:
      outState = outState
        .set('agencytoken', action.agencytoken)
        .set('agencyid', action.agencyid)
        .set('isAdmin', action.isAdmin)
        .set('evalScale', action.evalScale)
        .set('agencyName', action.agencyname)
        .set('evalScaleDisplay', action.evalScaleDisplay)
        .set('remotetoken', action.remotetoken)
        .set('email', action.email)
        .set('entity', action.entity)
        .set('trainings', action.trainings)
        .set('agencyTrainings', action.agencyTrainings)
        .set('autorisation',action.autorisation)
        .set('loginStatus', 'connected')
        .set('loading', false)
      return outState
    case AGENCY_LOGIN_ERROR:
      if (action.error === ' Error 401') {
        outState = outState
          .delete('agencytoken') // FIXME really check error
          .delete('remotetoken') // FIXME really check error
      }
      outState = outState
        .set('loginStatus', action.error)
        .set('error', action.error)
        .set('loading', false)
      return outState
    case AGENCY_CHECK_TOKEN:
      return state
        .set('loading', true)
        .set('loginStatus', 'checking token')
        .set('error', false)
    case AGENCY_CHECK_TOKEN_SUCCESS:
      outState = outState
        .set('agencytoken', action.agencytoken)
        .set('agencyid', action.agencyid)
        .set('isAdmin', action.isAdmin)
        .set('evalScale', action.evalScale)
        .set('agencyName', action.agencyname)
        .set('evalScaleDisplay', action.evalScaleDisplay)
        .set('email', action.email)
        .set('entity', action.entity)
        .set('trainings', action.trainings)
        .set('autorisation',action.autorisation)
        .set('loginStatus', 'connected')
        .set('loading', false)
      return outState
    case AGENCY_CHECK_TOKEN_ERROR:
      if (action.error === ' Error 401') {
        outState = outState
          .delete('agencytoken')
          .delete('agencyTokenTgdApi')
          .delete('remotetoken')
          .delete('agencyid')
          .delete('isAdmin')
          .delete('evalScale')
          .set('loginStatus', 'disconnected')
          .set('loading', false)
      } else {
        outState = outState
          .set('loginStatus', action.error)
          .set('error', action.error)
          .set('loading', false)
      }
      return outState
    case AGENCY_LOGIN_RESET:
      outState = outState
        .delete('agencytoken')
        .delete('remotetoken')
        .delete('agencyid')
        .delete('isAdmin')
        .delete('evalScale')
        .set('loginStatus', 'disconnected')
        .set('loading', false)
        .delete('autorisation')
      return outState
    case GET_AGENCYTOKEN:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_AGENCYTOKEN_SUCCESS:
      return state
        .set('agencytoken', action.agencytoken)
        .set('loading', false)
    case GET_TOKEN_SIMU:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['refresh', 'simuPlayers'], true)
        .delete('success')
    case GET_TOKEN_SIMU_SUCCESS:
      return state
        .set('token', action.token)
        .set('loading', false)
        .set('success', true)
    case GET_TOKEN_SIMU_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
        .set('success',false)
    case GET_TOKEN:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['refresh', 'players'], true)
    case GET_TOKEN_SUCCESS:
      return state
        .set('token', action.token)
        .set('loading', false)
    case GET_TOKEN_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case GET_TOKEN_AGENCY:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['refresh', 'agencies'], true)
    case GET_TOKEN_AGENCY_SUCCESS:
      return state
        .set('token', action.token)
        .set('loading', false)
        .set('success', true)
    case GET_TOKEN_AGENCY_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case GET_PLAYERS:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_PLAYERS_SUCCESS:
      return state
        .set('players', fromJS(action.players))
        .set('progressions', fromJS(action.progressions))
        .set('sequencesRaw', fromJS(action.sequencesRaw))
        .set('loading', false)
    case GET_PLAYERS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case GET_AGENCIES:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_AGENCIES_SUCCESS:
      return state
        .set('agencies', fromJS(action.agencies))
        .set('loading', false)
    case GET_AGENCIES_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case GET_SIMU_PLAYERS:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_SIMU_PLAYERS_SUCCESS:
      return state
        .set('simuPlayers', fromJS(action.simuPlayers))
        .set('totalSimuPlayers', fromJS(action.totalSimuPlayers))
        .set('pageSize', action.pageSize)
        .set('pageIndex', action.pageIndex)
        .set('loading', false)
        .delete('success')
    case GET_SIMU_PLAYERS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
        case GET_DASHBOARD:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_DASHBOARD_SUCCESS:
      return state
        .set('dashboard', fromJS(action.dashboard))
        .set('loading', false)
        .delete('success')
    case GET_DASHBOARD_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case GET_ARCHIVE_PLAYERS:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_ARCHIVE_PLAYERS_SUCCESS:
      return state
        .set('simuPlayers', fromJS(action.archivePlayers))
        .set('loading', false)
        .delete('success')
    case GET_ARCHIVE_PLAYERS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case DELETE_PLAYER:
      return state
        .set('loading', true)
        .set('error', false)
    case DELETE_PLAYER_SUCCESS:
      return state
        .set('players', fromJS(action.simuPlayers))
        .set('loading', false)
    case DELETE_PLAYER_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case DELETE_SIMU_PLAYER:
      return state
        .set('loading', true)
        .set('error', false)
    case DELETE_SIMU_PLAYER_SUCCESS:
      return state
        .set('simuPlayers', fromJS(action.simuPlayers))
        .set('loading', false)
    case DELETE_SIMU_PLAYER_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case DELETE_ARCHIVE:
      return state
        .set('loading', true)
        .set('error', false)
    case DELETE_ARCHIVE_SUCCESS:
      return state
        .set('archivePlayers', fromJS(action.archives))
        .set('loading', false)
    case DELETE_ARCHIVE_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case REFRESH_PLAYERS:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['refresh', 'players'], false)
    case REFRESH_PLAYERS_SUCCESS:
      return state
        .set('players', fromJS(action.players))
        .set('loading', false)
    case REFRESH_PLAYERS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case REFRESH_SIMU_PLAYERS:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['refresh', 'simuPlayers'], false)
    case REFRESH_SIMU_PLAYERS_SUCCESS:
      return state
        .set('simuPlayers', fromJS(action.simuPlayers))
        .set('loading', false)
    case REFRESH_SIMU_PLAYERS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case GET_FILTERED_AGENCIES:
      return state
        .set('loading', true)
        .set('error', false)
    case GET_FILTERED_AGENCIES_SUCCESS:
      return state
        .set('agencies', fromJS(action.agencies))
        .set('loading', false)
    case GET_FILTERED_AGENCIES_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case DELETE_AGENCY:
      return state
        .set('loading', false)
        .set('error', false)
    case DELETE_AGENCY_SUCCESS:
      return state
        .set('agencies', fromJS(action.agencies))
        .set('loading', false)
    case DELETE_AGENCY_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case REFRESH_AGENCIES:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['refresh', 'agencies'], false)
    case REFRESH_AGENCIES_SUCCESS:
      return state
        .set('agencies', fromJS(action.agencies))
        .set('loading', false)
    case REFRESH_AGENCIES_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)

    case SET_SCALE_EVAL:
      return state
        .set('loading', true)
        .set('error', false)
    case SET_SCALE_EVAL_SUCCESS:
      console.log(action.evalScaleDisplay)
      outState = outState
        .set('evalScale', action.scale)
        .set('evalScaleDisplay', action.evalScaleDisplay)
        .set('loading', false)
      return outState
    case SET_SCALE_EVAL_ERROR:
      outState = outState
        .set('error', action.error)
        .set('loading', false)
      return outState
    case CREATE_PLAYER:
      return state
        .setIn(['refresh', 'simuPlayers'], true)
        .set('loading', true)
        .set('error', false)
    case CREATE_PLAYER_SUCCESS:
      return state
        .set('player', action.player)
        .set(('loading', false))
    case CREATE_PLAYER_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case SIMU_PLAYER_EDIT:
      return state
        .setIn(['refresh', 'simuPlayers'], true)
        .set('loading', true)
    case SIMU_PLAYER_EDIT_SUCCESS:
      if (process.env.REACT_APP_TYPE === 'b2vm') {
        index = state.get('simuPlayers').toJS().findIndex(p => p.id === action.player.id)
      } else {
        index = state.get('simuPlayers').toJS().findIndex(p => p.id.toString() === action.player.id.toString())
      }
      return state
        .setIn(['simuPlayers', index], fromJS(action.player))
        .set('loading', false)
        .set('error', false)
        .set('success', true)
    case SIMU_PLAYER_EDIT_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case PLAYER_EDIT:
      return state
        .setIn(['refresh', 'players'], true)
        .set('loading', true)
    case PLAYER_EDIT_SUCCESS:
      index = state.get('players').toJS().findIndex(p => p.id.toString() === action.player.id.toString())
      return state
        .setIn(['players', index], fromJS(action.player))
        .set('loading', false)
        .set('error', false)
    case PLAYER_EDIT_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case VERIF_EMAIL:
      return state
        .set('loading', true)
        .set('error', false)
    case VERIF_EMAIL_SUCCESS:
      return state
        .set('email', action.email)
        .set('loading', false)
    case VERIF_EMAIL_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case CREATE_EVAL_FOR_PLAYER:
      return state
        .set('loading', true)
        .set('error', false)
    case CREATE_EVAL_FOR_PLAYER_SUCCESS:
      return state
        .set('evalForPlayer', action.evalForPlayer)
        .set('error', false)
    case CREATE_EVAL_FOR_PLAYER_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
    case CREATE_ARCHIVE_PLAYER:
      return state
        .set('loading', true)
        .set('error', false)
    case CREATE_ARCHIVE_PLAYER_SUCCESS:
      return state
        .set('simuPlayers', action.simuPlayers)
        .set('loading', false)
        .set('error', false)
    case CREATE_ARCHIVE_PLAYER_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
    case REVERT_ARCHIVE_PLAYER:
      return state
        .set('loading', true)
        .set('error', false)
    case REVERT_ARCHIVE_PLAYER_SUCCESS:
      return state
        .set('archivePlayers', action.archives)
        .set('loading', false)
        .set('error', false)
    case REVERT_ARCHIVE_PLAYER_ERROR:
      return state
        .set('loading', false)
        .set('error', true)
    case LOAD_JOURNEYS:
      return state
        .set('loading', true)
        .set('error', false)
    case LOAD_JOURNEYS_SUCCESS:
      return state
        .set('journeys', fromJS(action.journeys))
        .set('loading', false)
    case LOAD_JOURNEYS_ERROR:
      return state.set('error', action.error).set('loading', false)
    case AGENCY_EDIT:
      return state
        .setIn(['refresh', 'agencies'], true)
        .set('loading', true)
    case AGENCY_EDIT_SUCCESS:
      if (process.env.REACT_APP_TYPE === 'b2vm') {
        index = state.get('agencies').toJS().findIndex(a => a.id === action.agency.id)
      } else {
        index = state.get('agencies').toJS().findIndex(a => a.id.toString() === action.agency.id.toString())
      }
      return state
        .setIn(['agencies', index], fromJS(action.agency))
        .set('loading', false)
        .set('success', true)
    case AGENCY_EDIT_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
    case HIDE_MODAL:
      return state
        .delete('success')
    default:
      return state
  }
}

export default appReducer
