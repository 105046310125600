import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import moment from 'moment'
import './AgGrid.css'
import ModalConfirm from '../../components/ModalConfirm/index.js'
import { deleteSimuPlayerSaga, refreshSimuPlayersSaga, getSimuPlayersSaga, createArchivePlayerSaga, getDashboardSaga } from './saga.js'
import injectSaga from '../../utils/injectSaga.js'
import {deleteSimuPlayer, createArchivePlayer, getSimuPlayers } from '../App/actions.js'
import { GridHeaderContainer, HeaderContainer, ResetButtonContainer, ButtonContainer, ValidateButtonContainer, IconImg, InputContainer, Input, FilterContainer, ArrowPageLeft, ArrowPageRight, PaginationContainer, Bg, InactiveAction, DateFilter, ActionContainer, ActionEdit, EditIcon, Loader, LayoutContainer, StatusContainer, DeleteIcon, ArchiveIcon, ActionDelete, ActionArchive, Progression, ProgressionBar, ProgressionBarIn, ProgressionText, FirstContainer, ContainerFirstStudent, AvatarContainer, TextFirstCSV, ButtonImport, ImportImg, ImportText, ButtonCreate, TopBarContainer, SelectorContainer, ButtonTransfer, StatusSelector, LicenceSelector, DashboardContainer, FilterSubContainer } from './styledComponents.js'
import Avatar4 from '../../images/Avatar4.svg'
import AddStudent from '../../images/Add.svg'
import config from '../../config/configurator.js'
import UiEdit from '../../images/edit.svg'
import UiDelRed from '../../images/closeRed.svg'
import UiArchive from '../../images/Archive.svg'
import { AgGridReact } from 'ag-grid-react'
import UiMail from '../../images/mail.svg'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { Link } from 'react-router-dom'
import ModalArchive from '../../components/ModalArchive/index.js'
import Arrow from '../../images/Arrowdown.svg'
import Dashboard from '../../components/Dashboard/index'
import SearchIcon from '../../images/search.svg'
import ResetIcon from '../../images/delete.svg'
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

const SimuPlayersWorkshop = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers || [])
  const pageIndex = useSelector((state) => state.toJS().global.pageIndex || 0)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const agencyid = useSelector((state) => state.toJS().global.agencyid)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  const loading = useSelector((state) => state.toJS().global.loading)
  const dashboardData = useSelector((state) => state.toJS().global.dashboard || {})
  const totalSimuPlayers = useSelector((state) => state.toJS().global.totalSimuPlayers)
  const pageSize = useSelector((state) => state.toJS().global.pageSize || 2)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [actualDrivingTotal, setActualDrivingTotal] = useState('')
  const [licenceDate, setLicenceDate] = useState('')
  const [licenceAttempts, setLicenceAttempts] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalName, setModalName] = useState('')
  const [playerId, setPlayerId] = useState('')
  const [filters, setFilters] = useState({students: '', email: '', agency: 'all', licence: 'all', date: '', status: 'all'})
  const [activatedButton, setActivatedButton] = useState(false)
  const [activatedColor, setActivatedColor] = useState(false)
  
  useEffect(() => {
    dispatch(getSimuPlayers(filters, agencies, agencytoken, pageSize, 0))
    // dispatch(getDashboard(agencytoken))
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
  }, [loading])


  function findAgencyName (agencyId) {
    if (agencies == null) {
      return ''
    }
    if (agencyId == null) {
      return 'Non affilié'
    }
    let agency
    if (isB2VM) {
      agency = agencies.find(a => a.agencyId === agencyId)
    } else {
      agency = agencies.find(a => a.id === agencyId)
    }
    return agency != null ? agency.agencyname || agency.agencyName : 'Aucune agence'
  }

  function convertInTime (time, access) {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt(time / 60 % 60)
      const s = parseInt(time % 60)

      return h + 'h ' + m + 'm' + s + 's'
    }
    return 'Non démarré'
  }

  function confirmDelete (id, action, firstname) {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
  }

  const confirmArchivePlayer = (id, name, email) => {
    setShowArchiveModal(true)
    setModalName(name)
    setPlayerId(id)
  }

  function deletePlayer (id) {
    dispatch(deleteSimuPlayer(agencytoken, id, agencyid))
    dispatch(getSimuPlayers(filters, agencies, agencytoken, pageSize, 0))
  }


  const archivePlayer = (id) => {
    const data = {
      agencyToken: agencytoken,
      studentId: id,
      actualDrivingTotal,
      licenceDate: moment(licenceDate).toISOString(),
      licenceAttempts
    }
    dispatch(createArchivePlayer(data))
    // dispatch(getDashboard(agencytoken))
    setTimeout(() => {
      history.push('/simuplayers/archive')
    }, 10)
  }


const buildRows = () => {
   if (loading) {
     return null
   }

  return simuPlayers
  .filter(player => player.email.includes('@fake.tgd'))
  .map(player => {
    const { prog } = player
    const pTime = prog ? convertInTime(prog.PlayTime, player.access) : 0
    const pCount = prog && prog.Completion ? prog.Completion.Count : 0
    const pTotal = prog && prog.Completion ? prog.Completion.CountTotal : 0
    const pStatus = player.status
    const pLicence = process.env.REACT_APP_TYPE === "b2vm"
      ? player.trainingType
      : player.TrainingType === "1"
      ? "B"
      : player.TrainingType === "2"
      ? "BEA"
      : player.TrainingType === "3"
      ? "AAC"
      : ""
    return {
      eleve: `${player.firstname} ${player.lastname}`,
      email: player.email,
      agence: findAgencyName(player.agencyId),
      agencyId: player.agencyId,
      permis: pLicence,
      conduite: pTime,
      id: player.id || player._id,
      eval: player.evalHours || player.evaluationScore || player.evalScore || "",
      date: moment(player.dateCreation).format('DD/MM/YYYY'),
      volume: player.volume || null,
      pCount,
      pTotal,
      status: pStatus
    }
  })
}


  const licenceArray = [
    { value: 'all', label: 'Tous' }, 
    { value: 'B', label: 'B' },
    { value: 'BEA', label: 'BEA' },
    { value: 'AAC', label: 'AAC' },
    { value: 'BEA AAC', label: 'BEA AAC' },
 ]

  const DisplayLicenceSelector = () => {
      return (
        <div style={{marginLeft: isAdmin ? '2px' : '2px', zIndex: '1'}}>
          <LicenceSelector
            classNamePrefix='react-select'
            multiple={false}
            isSearchable={false}
            value={licenceArray.value}
            defaultValue={filters.licence === 'B' ? licenceArray[1] : filters.licence === 'BEA' ? licenceArray[2] : filters.licence === 'AAC' ? licenceArray[3] : filters.licence === 'BEA AAC' ? licenceArray[4] : licenceArray[0]}
            onChange={(e) => handleClickFilters(e, 'licence')}
            options={licenceArray}>
          </LicenceSelector>
        </div>
      )
  }

  const statusArray = [
    { value: 'all', label: 'Tous'},    
    { value: 'Actif', label: 'Actif'},
    { value: 'Inactif', label: 'Inactif'},
 ]

  const DisplayStatusSelector = () => {
      return (
        <div style={{marginLeft:'216px', zIndex: '1'}}>
          <StatusSelector
            classNamePrefix='react-select'
            multiple={false}
            isSearchable={false}
            value={statusArray.value}
            defaultValue={filters.status === 'Actif' ? statusArray[1] : filters.status === 'Inactif' ? statusArray[2] : statusArray[0]}
            onChange={(e) => handleClickFilters(e, 'status')}
            options={statusArray}>
          </StatusSelector>
        </div>
      )
  }

  const DisplayAgencySelector = () => {
    if (!agencies) {
      return
    }

    const sortedAgencies = agencies.sort((a, b) => {
      const valA = a.agencyname || a.agencyName
      const valB = b.agencyname || b.agencyName

      if (valA < valB) {
        return -1
      }
      if (valA > valB) {
        return 1
      }
      return 0
    })

    const agencyArray = [
      { value: 'all', label: 'Toutes les agences' },
      ...sortedAgencies.map(agency => ({
        value: agency.agencyId,
        label: agency.agencyname || agency.agencyName
      }))
    ]
    
    const agencyIndex = agencyArray.findIndex(ag => filters.agency === ag.value)

    return (
      <div style={{marginLeft:'1px', zIndex: '1'}}>
        <StatusSelector
          classNamePrefix='react-select'
          multiple={false}
          isSearchable={false}
          value={agencyArray.value}
          defaultValue={filters.agency === 'all' ? agencyArray[0] : filters.agency === '' ? agencyArray[0] : agencyArray[agencyIndex]}
          onChange={(e) => handleClickFilters(e, 'agency')}
          options={agencyArray}>
        </StatusSelector>
      </div>
    )
}

  const filterdate = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue
      if (dateAsString == null) return -1
      var dateParts = dateAsString.split('/')
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      )
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
    },
    browserDatePicker: true,
  }

  const basicGrid = [
    { width: config.features.eval === true  && !isAdmin ? 224 : 200, floatingFilter: true, floatingFilterComponentParams: { suppressFilterButton: true },suppressMovable: true, headerName: 'Elèves', field: 'eleve', tooltipField: 'eleve', cellClass: (params) => params.data.status !== 0 ? 'rowGreyBackgroundwithImgActive' : 'rowGreyBackgroundwithImgInactive', suppressMenu: true, cellRendererFramework: (params) => {
      return (config.title === 'ECF' || 'PP ADMIN') && params.data.status !== 0 ?
      <Link style={{ paddingLeft: '2.2rem', textOverflow: 'ellipsis', lineHeight: '1.1rem' }}to={{ pathname: `simuplayers/edit/${params.data.id}`,query: { id: params.data.id } }}>{params.data.eleve}</Link> :
      <div style={{ paddingLeft: '2.2rem', filter: 'invert(50%)' }}>{params.data.eleve}</div> } },
    { width: config.features.eval === true && !isAdmin ? 224 : 200, suppressMovable: true, headerName: 'Email', field: 'email', tooltipField: 'email', cellClass: ' rowGreyBackground', cellRendererFramework: (params) => {
      return <div style={{filter: params.data.status === 0 ? 'invert(50%)' : 'inherit'}}>{params.data.email}</div>
    }},
    { width: config.features.eval === true ? 114 : 114, suppressMovable: true, headerName: 'Permis', field: 'permis', cellClass: ' rowGreyBackground', suppressMenu: true, cellRendererFramework: (params) => {
      return <div style={{filter: params.data.status === 0 ? 'invert(50%)' : 'inherit'}}>{params.data.permis}</div>
    }}
  ]
  const evalGrid = [
    {
      width: 98,
      headerName: 'Eval\'',
      field: 'eval',
      suppressMovable: true,
      cellClass: 'rowGreenBackground',
      cellRendererFramework: (params) => {
        return (
          config.title === ('ECF' && !isAdmin) || ('PP ADMIN' && !isAdmin) ? <Link style={{ textDecoration: 'underline' }} to={{ pathname: `/simuplayers/eval/${params.data.id}`, query: { id: params.data.id } }}>{params.data.eval}</Link> : <div>{params.data.eval}</div>
        )
      }
    },
    { width: 116, suppressMovable: true, headerName: 'Date', field: 'date',cellClass: 'rowGreenBackground',filter: 'agDateColumnFilter',filterParams: filterdate}
  ]
  const simuGrid = [
    { width: config.features.eval === true ? 100 : 138, suppressMovable: true, headerName: 'Conduite', field: 'conduite', cellClass: 'rowBlueBackground', suppressMenu: true, cellRendererFramework: (params) => { return <Link style={{ fontWeight: '500', lineHeight: '3.2rem' }} to={{ pathname: `/simuplayers/stats/${params.data.id}` }}><div>{params.data.conduite}</div></Link> } },
    {
      suppressMovable: true,
      suppressMenu: true,
      field: 'Progression',
      cellClass: 'rowBlueBackground',
      width: config.features.eval === true ? 130 : 196,
      cellRendererFramework: (params) => {
        return (
          <Link to={{ pathname: `/simuplayers/stats/${params.data.id}`, query: { id: params.data.id } }}>
            <Progression>
              <ProgressionBar style={{ width: config.features.eval === true ? '60%' : '80%', marginLeft: config.features.eval === true ? '0.2rem' : '-0.7rem' }}>
                <ProgressionBarIn style={{ width: `${params.data.pCount ? params.data.pCount / params.data.pTotal * 100 : 0}%` }} />
              </ProgressionBar>
              <ProgressionText style={{ marginLeft: config.features.eval === true ? '-0.6rem' : '0.5rem' }}>{params.data.pCount}/{params.data.pTotal}</ProgressionText>
            </Progression>
          </Link>
        )
      }
    }
  ]
  const statusGrid = [
    {
      width: config.title === ('ECF' && isAdmin) || ('PP ADMIN' && isAdmin) ? 190 : config.title === ('ECF' && !isAdmin) || ('PP ADMIN' && !isAdmin) ? 190 : 100,
      field: 'Status',
      cellClass: 'rowGreyBackground',
      suppressMovable: true,
      cellRendererFramework: (params) => {
        return (
          config.title === 'ECF' || 'PP ADMIN'
            ? params.data.status === 0
                ? (
                  <ActionContainer>
                    <ButtonTransfer style={{ display: 'flex', alignItems:'center', justifyContent: 'center', textDecoration: 'unset', fontSize:'0.75rem', marginLeft:'0.3rem', width:'9.125rem', height: '1.875rem', borderRadius:'5px', border: '1px solid black'}} to={`simuplayers/transfer/${params.data.id}`}>Transférer le compte</ButtonTransfer>
                  </ActionContainer> 
                )
                : (
                  <ActionContainer>
                     <div>Compte Actif</div>
                  </ActionContainer>)
            :
            <ActionContainer>
                <Link to={`simuplayers/edit/${params.data.id}`}>
                  <ActionEdit>
                    <EditIcon src={config.features.eval === true ? UiMail : UiEdit} />
                  </ActionEdit>
                </Link>
                <ActionDelete onClick={() => confirmDelete(params.data.id, 'Supprimer', params.data.eleve)}>
                  <DeleteIcon
                    src={config.features.eval === true ? UiEdit : UiDelRed}
                  />
                </ActionDelete>
                {config.features.eval === true ? <ActionArchive> <ArchiveIcon src={UiArchive} /></ActionArchive> : ''}
            </ActionContainer>
        )
      }
    }
  ]
  const actionGrid = [
    {
      suppressMovable: true,
      width: config.title === ('ECF' && isAdmin) || ('PP ADMIN' && isAdmin) ? 90 : config.title === ('ECF' && !isAdmin) || ('PP ADMIN' && !isAdmin) ? 128 : 100,
      suppressMenu: true,
      field: 'Actions',
      tooltipField: 'Actions',
      cellClass: 'rowGreyBackground',
      cellRendererFramework: (params) => {
        return (
          config.title === 'ECF' || 'PP ADMIN'
          ?
            <StatusContainer style={{width: isAdmin ? '5rem' : '7rem'}}>
              {params.data.status !== 0 ? 
                <Link to={`simuplayers/edit/${params.data.id}`}>
                  <ActionEdit>
                    <EditIcon src={UiEdit} />
                  </ActionEdit>
                </Link>
              :
              <InactiveAction style={{cursor: 'unset'}}>
                <EditIcon style={{filter: 'invert(50%)'}} src={UiEdit} />
              </InactiveAction>}
              {!isAdmin && params.data.status !== 0 ? 
                <ActionArchive onClick={() => confirmArchivePlayer(params.data.id, params.data.eleve, params.data.email)}>
                  <ArchiveIcon src={UiArchive} />
                </ActionArchive>
              : isAdmin ? 
                null : 
                <InactiveAction>
                  <ArchiveIcon style={{filter: 'invert(50%)'}}  src={UiArchive} />
                </InactiveAction>}
              <ActionDelete onClick={() => confirmDelete(params.data.id, 'Attention, vous allez supprimer définitivement le compte de ', params.data.eleve)}>
                <DeleteIcon src={UiDelRed} />
              </ActionDelete>
            </StatusContainer>
          :
          ''
        )
      }
    }
  ]
  
  const agencyGrid = {
    width: config.features.eval === true ? 190 : 259,
    suppressMovable: true,
    headerName: 'Agence',
    floatingFilter: true,
    suppressMenu: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    field: 'agence',
    cellClass: (params) =>  params.data.agence === 'Aucune agence' ? 'rowGreyBackgroundwithAgencyImgInactive' : 'rowGreyBackgroundwithAgencyImgActive',
    cellRendererFramework: (params) => {
      return config.title === 'ECF' || 'PP ADMIN' ?
        <Link style={{
          display:'flex',
          marginLeft:'2.2rem',
          textOverflow: 'ellipsis',
          filter: params.data.agence === 'Aucune agence' ? 'invert(50%)' : 'unset',
          cursor: params.data.agence === 'Aucune agence' ? 'unset' : 'pointer ' }}
          to={{ pathname: params.data.agence === 'Aucune agence' ? '' : `agency/edit/${params.data.agencyId}`, query: { id: params.data.agencyId } }}>
            {params.data.agence}
        </Link> :
        <div>{params.data.agence}</div>
      }
    }

  const adminCol = () => {
    basicGrid.splice(2, 0, agencyGrid)
    return basicGrid
  }

  const columns = () => {
    if (config.title === 'ECF' || 'PP ADMIN') {
      if (isAdmin) return adminCol().concat(evalGrid).concat(statusGrid).concat(actionGrid)
      else return basicGrid.concat(evalGrid).concat(statusGrid).concat(actionGrid)
    }
    if (config.features.simu && !config.features.eval) {
      if (isAdmin) return adminCol().concat(simuGrid).concat(statusGrid)
      else basicGrid.concat(simuGrid).concat(statusGrid)
    }
    if (!config.features.simu && config.features.eval) {
      if (isAdmin) return adminCol().concat(evalGrid).concat(statusGrid)
    }
    if (config.features.simu && config.features.eval) {
      if (isAdmin) return adminCol().concat(evalGrid).concat(simuGrid).concat(statusGrid)
    }
  }

  const gridOptions = {
    defaultColDef: { filter: false, sortable: false },
    columnDefs: columns(),
    enableBrowserTooltips: true
  }

  if (loading === true || totalSimuPlayers === undefined) {
    return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',fontSize:'2rem', flexDirection:'column'}}>
          <Loader></Loader>
        </div>
    )
  }

  const FirstStudent = () => {
    return (
      <FirstContainer>
        <ContainerFirstStudent>
          <AvatarContainer>
            <img alt='avatar 4' src={Avatar4} />
          </AvatarContainer>
          <TextFirstCSV>Vous pouvez <b>créer un élève</b> dans votre console de gestion</TextFirstCSV>
          <ButtonImport>
            <ButtonCreate to='/simuplayers/create' style={{ width: '173px' }}>
              <ImportImg src={AddStudent} />
              <ImportText>Créer un élève</ImportText>
            </ButtonCreate>
          </ButtonImport>
        </ContainerFirstStudent>
      </FirstContainer>
    )
  }

  const totalUnarchivedPlayers = totalSimuPlayers - dashboardData.totalArchiveStudents

  const goNextPage = () => {
    if (pageIndex + 1 >= totalUnarchivedPlayers / pageSize) return
    dispatch(getSimuPlayers(filters, agencies, agencytoken, pageSize, pageIndex + 1))
  }

  const goPrevPage = () => {
    if (pageIndex <= 0) return
    dispatch(getSimuPlayers(filters, agencies, agencytoken, pageSize, pageIndex - 1))
  }

  const handleClickFilters = (e, type) => {
    const updatedFilters = { ...filters }
  
    if (type === 'students') {
      updatedFilters.students = e.target.value
    } else if (type === 'agency') {
      updatedFilters.agency = e.value
    } else if (type === 'email') {
      updatedFilters.email = e.target.value
    } else if (type === 'date') {
      updatedFilters.date = e.target.value
    } else if (type === 'licence') {
      updatedFilters.licence = e.value
    } else if (type === 'status') {
      updatedFilters.status = e.value
    }
  
    setActivatedButton(true)
    setFilters(updatedFilters)
    setActivatedColor(false)
  }

  const handleClickFindSimuPlayers = () => {
    dispatch(getSimuPlayers(filters, agencies, agencytoken, pageSize, 0))
    const hasFilters = filters.students || filters.agency !== 'all' || filters.email || filters.licence !== 'all' || filters.date || filters.status !== 'all'
    const isAllSelected = filters.agency === 'all' || filters.status === 'all' || filters.licence === 'all'
    setActivatedColor(hasFilters && isAllSelected)
    setActivatedButton(hasFilters && isAllSelected)
  }

  return (
    <Bg>
    {console.log(`TRYING TO DISPLAY WORKSHOPS ${loading} ps ${pageSize} ${dashboardData} ${dashboardData.totalStudents} ${totalSimuPlayers}`)}
      {
        (!loading /* && dashboardData && dashboardData.totalStudents > 0 && totalSimuPlayers !== undefined */)// removed cond, never true with high amount of players (totalSimuPlayers and dashboardData fails)
        ? <LayoutContainer>
            <TopBarContainer style={{'width': isAdmin ? '1215px' : '1106px' }}>
              {/* {config.features.eval ? <EvalBar  style={{marginLeft: config.features.eval && isAdmin ? '57.9%' : '50.7%' }}><p style={{marginBottom:'0.5rem'}}>Évaluation</p></EvalBar> : null} */}
            </TopBarContainer>
            <HeaderContainer style={{'width': isAdmin ? '1215px' : '1106px' }}>
              <DashboardContainer>
                <Dashboard height={'56px'} type='simuPlayers' />
                {
                  simuPlayers && simuPlayers.length > 0
                  ?  <PaginationContainer>
                        <ArrowPageLeft src={Arrow} style={{opacity: pageIndex + 1 === 1 ? 0 : 1, cursor: pageIndex + 1 === 1 ? 'initial' : 'pointer'}} onClick={() => goPrevPage()}></ArrowPageLeft>
                          <div>{pageIndex + 1} / {Math.ceil((totalUnarchivedPlayers / pageSize)) <= 0 ? 1 : Math.ceil((totalUnarchivedPlayers / pageSize))}</div>
                        <ArrowPageRight src={Arrow} style={{opacity: simuPlayers.length < pageSize ? 0 : 1, cursor: simuPlayers.length < pageSize ? 'initial' : 'pointer'}} onClick={() => goNextPage()}></ArrowPageRight>
                      </PaginationContainer>
                  : null
                }
              </DashboardContainer>
            </HeaderContainer>
            <div className={
              isAdmin && config.features.eval
                ? 'ag-theme-alpine-Admin-Eval'
                : isAdmin && !config.features.eval
                ? 'ag-theme-alpine'
                : 'ag-theme-alpine-Eval'
              } style={{height: 610, width: !isAdmin ? 1106 : 1215, position: 'relative' }}>
            <GridHeaderContainer>
            <FilterContainer>
              <FilterSubContainer style={{'width': isAdmin ? '1200px' : '1090px', marginRight: '15px', backgroundColor: activatedColor ? '#DFF7E6' : '#eff0f6' }}>
                <InputContainer style={{'width': isAdmin ? '192px' : '380px'}}>
                  <IconImg src={SearchIcon} />
                  <Input onChange={(e) => handleClickFilters(e, 'students')} value={filters.students || ''} placeholder='Chercher un élève' />
                </InputContainer>
                <InputContainer style={{'width': isAdmin ? '194px' : '370px'}}>
                  <IconImg src={SearchIcon} />
                  <Input onChange={(e) => handleClickFilters(e, 'email')} value={filters.email || ''} placeholder={'Chercher un mail'} />
                </InputContainer>
                <SelectorContainer style={{'width': isAdmin ? '800px' : '1106px', top: '112px', marginLeft: '4px' }}>
                  {isAdmin ? DisplayAgencySelector() : null}
                  {DisplayLicenceSelector()}
                  {DisplayStatusSelector()}
                  <DateFilter value={filters.date || ''} type="date" onChange={e => handleClickFilters(e, 'date')} />
                </SelectorContainer>
                <ButtonContainer>
                  <ValidateButtonContainer 
                    onClick={() => handleClickFindSimuPlayers()}
                    style={{display: activatedButton ? 'flex' : 'none'}}>
                    <IconImg style={{paddingRight: '4px'}} src={SearchIcon} />
                  </ValidateButtonContainer>
                  <ResetButtonContainer 
                    onClick={() => {
                      setFilters({students: '', email: '', agency: 'all', licence: 'all', date: '', status: 'all'})
                      dispatch(getSimuPlayers({students: '', email: '', agency: 'all', licence: 'all', date: '', status: 'all'}, agencies, agencytoken, pageSize, 0))
                      setActivatedButton(false)
                      setActivatedColor(false)
                    }}
                    style={{display: activatedButton ? 'flex' : 'none'}}>
                    <IconImg style={{paddingRight: '4px'}} src={ResetIcon} />
                  </ResetButtonContainer>
                </ButtonContainer>
                </FilterSubContainer>
              </FilterContainer>
            </GridHeaderContainer>
              <AgGridReact
                rowData={buildRows()}
                gridOptions={gridOptions}
                rowHeight={55}
                overlayNoRowsTemplate={'Aucun élève trouvé'}
              />
            </div>
        </LayoutContainer>
        : FirstStudent()
      }
      <ModalConfirm
        show={showModal}
        text={modalText}
        name={modalName}
        confirm={() => { deletePlayer(playerId); setShowModal(false) }}
        cancel={() => setShowModal(false)}
        confirmText='Supprimer'
      />
      <ModalArchive
        show={showArchiveModal}
        name={modalName}
        actualDrivingTotal={setActualDrivingTotal}
        licenceDate={setLicenceDate}
        licenceAttempts={setLicenceAttempts}
        cancel={() => {setShowArchiveModal(false)}}
        confirm={() => { archivePlayer(playerId); setShowArchiveModal(false) }}
        enableButton={(actualDrivingTotal.length > 0 && licenceDate.length > 0 && licenceAttempts.length > 0) || false}
      />
    </Bg>
  )
}

const withConnect = connect(null, null)
const withRefresh = injectSaga({ key: 'refreshSimuPlayers', saga: refreshSimuPlayersSaga })
const withLoader = injectSaga({ key: 'SimuPlayersLoader', saga: getSimuPlayersSaga })
const withCreate = injectSaga({key: 'createArchivePlayer', saga: createArchivePlayerSaga})
const withDelete = injectSaga({ key: 'deleteSimuPlayers', saga: deleteSimuPlayerSaga })
const withDashboard = injectSaga({key: 'getDashboard', saga: getDashboardSaga})
const arg = compose(withRefresh, withLoader, withCreate, withConnect, withDelete, withDashboard)(SimuPlayersWorkshop)

export default arg
